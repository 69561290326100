import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationExtras, ActivatedRoute, Data } from '@angular/router';
import { ChatService } from '../../services/chat.service';
import { CommonService } from '../../common.service';
import { APIURL } from '../../url'
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

    currentLang = 'en';
    toggleClass = 'ft-maximize';
    public isCollapsed = true;
    LoginUser;
    user = {
        Password: null,
        phoneNumber: null
    }
    profile = {
        Image: "",
        Name: ""
    }
    ProfileImg = "assets/images/E2L/userImg.png";
    UpdatedUser;


    PurchesedClasses = {
        Class: null
    }
    AvailableClasses = [];

    oldUser = {
        phoneNumber: null,
        Password: null
    }

    showOldOrNewPasswordFields = false;

    SchoolName = "";
    PaidUserHistory = [];
    paidCourses = [];

    ListOfCourses = [];
    constructor(public translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        public chat: ChatService,
        public CommonService: CommonService,
        private toastr: ToastrService) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'pt');
        this.UserData();
    }


    UserData() {
        this.LoginUser = JSON.parse(localStorage.getItem('LoginUser'));
        if (this.LoginUser != null) {
            this.user.phoneNumber = this.LoginUser.MobileNumber;
            this.oldUser.phoneNumber = this.LoginUser.MobileNumber;

            if (this.LoginUser.SchoolName) {
                this.SchoolName = this.LoginUser.SchoolName;
            }
            
            if (this.LoginUser.Image != null) {
                this.ProfileImg = this.LoginUser.Image;
            }
            this.profile.Name = this.LoginUser.Name;

            this.AvailableClasses = this.LoginUser.PurchasedClasses;
        }
        this.getPaidCourses();
    }
    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    logout() {
        this.chat.UserUpdateToOffline(this.LoginUser)
        this.router.navigate(['/home']);
        localStorage.setItem('LoginUser', ""); // localStorage.clear();

    }



    getPaidCourses() {
        var obj = {
          MobileNumber: this.LoginUser.MobileNumber
        }
        var url = APIURL.GET_PAID_COURSES;
        this.CommonService.postMethod(url, obj)
          .subscribe((data: Data) => {
            this.PaidUserHistory = data.response;
            this.PaidUserHistory.forEach(x => {
              x.Classes.forEach(y => {
                this.paidCourses.push(y);
              })
            })
    
          })
      }
    
    




    verifyPassword() {
        var obj = {
            phoneNumber: this.oldUser.phoneNumber,
            Password: this.oldUser.Password
        }
        var url = APIURL.VERIFY_OTP;
        this.CommonService.postMethod(url, obj)
            .subscribe((data: Data) => {
                if (data.success == true) {
                    this.toastr.success('Your password is verified , Enter New password', 'Success!');
                    this.oldUser.Password = '';
                    this.showOldOrNewPasswordFields = true;
                }
                else {
                    this.toastr.warning("Password Does't Match, Please Enter Correct Password", 'Error!');
                }
            })
    }



    updatePassword() {
        var url = APIURL.SET_PASSWORD
        this.CommonService.postMethod(url, this.user)
            .subscribe((data: Data) => {
                if (data.success) {
                    this.toastr.success('Password Updated Successfully', 'Success !')
                    this.user.Password = '';
                } else {
                    this.toastr.success('Password Update Failed', 'Error !')
                }
            })
    }




    url: string = '';
    fileToUpload: File = null;
    uploadImgUrl = '';
    onSelectFile(file: FileList) {
        this.fileToUpload = file.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.url = event.target.result;
            this.getimageUrl(event.target.result);
        }
        reader.readAsDataURL(this.fileToUpload)
    }
    getimageUrl(url) {
        this.uploadImgUrl = url;
        this.profile.Image = this.uploadImgUrl;
        this.ProfileImg = this.uploadImgUrl;
        //   this.send();
    }


    UpdateProfile() {
        var obj = {
            MobileNumber: this.LoginUser.MobileNumber,
            Image: this.profile.Image,
            Name: this.profile.Name
        }
        var url = APIURL.UPDATE_PROFILE
        this.CommonService.postMethod(url, obj)
            .subscribe((data: Data) => {
                if (data.success) {
                    this.UpdatedUser = data.response[0];
                    localStorage.setItem('LoginUser', JSON.stringify(this.UpdatedUser));
                    this.toastr.success('Profile Updated Successfully', 'Success !');
                    this.UserData();
                } else {

                    this.toastr.success('Profile Update Failed', 'Error !');
                }
            })
    }




    UpdateClass() {
        var obj = {
            MobileNumber: this.LoginUser.MobileNumber,
            Class: this.PurchesedClasses.Class,
        }
        var url = APIURL.UPDATE_STUDENT_CLASS;
        this.CommonService.postMethod(url, obj)
            .subscribe((data: Data) => {
                if (data.success == true) {
                    this.toastr.warning('Please Logout and Login', 'Success !');
                    this.toastr.success('successfully Updated', 'Success !');
                }
                else {
                    this.toastr.warning('Update Failed', "Error !");
                }
            })
    }






}
