// var baseUrl = "http://localhost:3001/"
var baseUrl = ""
// var baseUrl = "https://www.easy2learn.in/" 

export const APIURL = {

  REGISTRATION: baseUrl + "api/userRegistration",
  LOGIN: baseUrl + "api/login",

  GET_ALL_USER_DATA: baseUrl + "api/getUsersList",

  // GET_USER_DATA: baseUrl + "api/login",
  GET_USER_DATA: baseUrl + 'api/getUserData',

  UPDATEPAYMENT: baseUrl + "api/UpdatePayment",
  DELETEUSER: baseUrl + "api/DeleteUser",
  SET_PASSWORD: baseUrl + "api/setPassword",
  UPDATE_USER_DETAILS: baseUrl + "api/UpdateUserDetails",

  UPDATE_USER_READ_QUESTIONSETS: baseUrl + "api/UpdateSubQuestionSets/Values",
  GET_USER_READ_QUESTIONSETS: baseUrl + "api/GetUserCompletedSubQuestionSets",

  UPDATE_PURCHASED_CLASSES: baseUrl + "api/UpdatePurchasedClasses",
  REMOVE_PURCHASED_CLASSES: baseUrl + "api/RemovePurchasedClasses",

  UPDATE_PAID_CLASSES: baseUrl + "api/UpdatePaidClasses",
  REMOVE_PAID_CLASSES: baseUrl + "api/RemovePaidClasses",

  GET_USERS_COUNT: baseUrl + "api/getUserCount",
  UPDATE_USER_IS_ACTIVE: baseUrl + "api/UpdateUserIsActive",

  //Get Users In Diferent ways
  GET_ALL_USERS_FOR_SCHOOL_ANALYTICS: baseUrl + "api/get_All_Users_for_School_Analytics",
  GET_ALL_USERS_FOR_COLLEGES: baseUrl + "api/get_All_Users_for_Colleges",



  INSERT_DASHBOARD: baseUrl + "api/InsertDashBoard",
  INSERT_BROWSE_CHAPTERS: baseUrl + "api/InsertBrouseChapters",
  INSERT_QUESTIONSETS: baseUrl + "api/InsertQuestionSets",
  INSERT_VIDEO_LECTURES: baseUrl + "api/InsertVideoLectures",
  INSERT_CONCEPTS: baseUrl + "api/InsertConcepts",
  INSERT_SUB_QUESTIONSETS: baseUrl + "api/InsertSubQuestionSets",
  INSERT_CHAPTER_TOPICS: baseUrl + "api/InsertChapterTopic",
  INSERT_QUIZ_DATA: baseUrl + "api/InsertQuizData",
  INSERT_ASSESSMENT_DATA: baseUrl + "api/InsertAssessments",
  INSERT_BOARDS_DATA: baseUrl + "api/InsertBoards",
  INSERT_E2LQUIZ_DATA: baseUrl + "api/InsertE2lQuizData",
  INSERT_E2LASSESSMENT_DATA: baseUrl + "api/InsertE2lAssessments",
  INSERT_MINDMAP_DATA: baseUrl + "api/InsertMindMap",
  INSERT_WORKSHEETS_DATA: baseUrl + "api/InsertWorkSheets",
  INSERT_NCRT_SOLUTIONS_DATA: baseUrl + "api/InsertNcrtSolutions",


  GET_DASHBOARD_DATA: baseUrl + "api/getDashboardData",
  GET_BROWSE_CHAPTERS: baseUrl + "api/getBrowseChapters",
  GET_SUBJECT_BROWSE_CHAPTERS: baseUrl + "api/getSubjectBrowseChapters",
  GET_BROWSECHAPTERS_WITH_PERCENTAGE: baseUrl + "api/getBrowseChapterswithUserCompletedPercentage",

  GET_VIDEO_LECTURES: baseUrl + "api/getVideoLectures",
  GET_CONCEPTS: baseUrl + "api/getConcepts",
  GET_QUESTIONSETS: baseUrl + "api/getQuestionSets",
  GET_SUB_QUESTIONSETS: baseUrl + "api/getSubQuestionSets",
  // GET_CHAPTER_TOPICS: baseUrl + "api/getChapterTopicList",
  GET_QUIZ_DATA: baseUrl + "api/getQuizData",
  GET_ASSESSMENT_DATA: baseUrl + "api/getAssessmentData",

  GET_ASSESSMENT_DATA_For_Reports: baseUrl + "api/getAssessmentData_For_Reports",

  GET_BOARDS_DATA: baseUrl + "api/getBoards",
  GET_EXAMS_DATA: baseUrl + "api/getExams",
  GET_E2LQUIZ_DATA: baseUrl + "api/getE2lQuizData",
  GET_E2LASSESSMENT_DATA: baseUrl + "api/getE2lAssessments",
  GET_MINDMAP_DATA: baseUrl + "api/getMindMaps",
  GET_WORKSHEETS_DATA: baseUrl + "api/getWorkSheets",
  GET_NCRT_SOLUTIONS_DATA: baseUrl + "api/getNcrtSolutions",


  UPDATE_DASHBOARD_DATA: baseUrl + "api/UpdateDashboardData",
  DELETE_DASHBOARD_DATA: baseUrl + "api/DeleteDashboardData",

  UPDATE_BROWSE_CHAPTERS_DATA: baseUrl + "api/UpdateBrowseChaptersdData",
  DELETE_BROWSE_CHAPTERS_DATA: baseUrl + "api/DeleteBrowseChaptersData",

  UPDATE_VIDEO_LECTURES_DATA: baseUrl + "api/UpdateVideoLecturesdData",
  DELETE_VIDEO_LECTURES_DATA: baseUrl + "api/DeleteVideoLecturesdData",

  UPDATE_QUESTION_SET_DATA: baseUrl + "api/UpdateQuestionSetData",
  DELETE_QUESTION_SET_DATA: baseUrl + "api/DeleteQuestionSetData",

  UPDATE_CONCEPT_DATA: baseUrl + "api/UpdateConceptData",
  DELETE_CONCEPT_DATA: baseUrl + "api/DeleteConceptData",

  UPDATE_SUB_QUESTION_SET_DATA: baseUrl + "api/UpdateSubQuestionSetData",
  DELETE_SUB_QUESTION_SET_DATA: baseUrl + "api/DeleteSubQuestionSetData",

  UPDATE_CHAPTER_TOPICS_DATA: baseUrl + "api/UpdateChapterTopicsData",
  DELETE_CHAPTER_TOPICS_DATA: baseUrl + "api/DeleteChapterTopicsData",

  UPDATE_QUIZ_DATA: baseUrl + "api/UpdateQuizData",
  DELETE_QUIZ_DATA: baseUrl + "api/DeleteQuizData",

  UPDATE_ASSESSMENTS: baseUrl + "api/UpdateAssessments",
  DELETE_ASSESSMENTS: baseUrl + "api/DeleteAssessments",

  UPDATE_BOARDS: baseUrl + "api/UpdateBoards",
  DELETE_BOARDS: baseUrl + "api/DeleteBoards",

  UPDATE_E2LQUIZ_DATA: baseUrl + "api/UpdateE2lQuizData",
  DELETE_E2LQUIZ_DATA: baseUrl + "api/DeleteE2lQuizData",

  UPDATE_E2LASSESSMENTS: baseUrl + "api/UpdateE2lAssessments",
  DELETE_E2LASSESSMENTS: baseUrl + "api/DeleteE2lAssessments",

  UPDATE_MINDMAPS: baseUrl + "api/UpdateMindMap",
  DELETE_MINDMAPS: baseUrl + "api/DeleteMindMap",

  UPDATE_WORKSHEETS: baseUrl + "api/UpdateWorkSheets",
  DELETE_WORKSHEETS: baseUrl + "api/DeleteWorkSheets",

  UPDATE_NCRT_SOLUTIONS: baseUrl + "api/UpdateNcrtSolutions",
  DELETE_NCRT_SOLUTIONS: baseUrl + "api/DeleteNcrtSolutions",

  /*=====================ADMIN LOGIN  ===================*/

  ADMIN_LOGIN: baseUrl + "api/AdminLogin",
  ADMIN_REGISTRATION: baseUrl + "api/AdminRegistration",
  GET_ADMIN_INFO: baseUrl + 'api/getAdminInfo',
  DELETE_ADMIN_INFO: baseUrl + 'api/DeleteAdminRecord',


  INSERT_NUMBER_OF_VISITED_USERS: baseUrl + 'api/InsertNumberOfVisitedUsers',
  GET_VISITED_USERS: baseUrl + 'api/GetVisitedUsers',
  /*=====================ADMIN LOGIN  ===================*/



  /*=====================sms otp  ==================== */
  // SEND_OTP : baseUrl + 'api/sendOTP'

  USER_REGISTRATION_WITH_OTP: baseUrl + 'api/userRegisterWithOTP',
  VERIFY_OTP: baseUrl + 'api/VerifyOTP',
  RESEND_OTP: baseUrl + 'api/resendOTP',

  LOGIN_VERIFY_OTP: baseUrl + 'api/checkUser',

  VERIFY_FORGOT_PASSWORD_OTP: baseUrl + 'api/VerifyForgotPasswordOTP',

  GET_USERS_WITH_OTP: baseUrl + 'api/GetUsersWithOtp',
  DELETE_USERS_WITH_OTP: baseUrl + 'api/DeleteUserWithOtp',

  /*====================== bookmark ==============*/
  ADD_BOOKMARK: baseUrl + 'api/Concept/Bookmark',
  GET_BOOKMARKS_LIST: baseUrl + 'api/GetBookmarksList',
  REMOVE_BOOKMARK: baseUrl + 'api/Concept/Bookmark/delete',

  ADD_NOTEBOOK_NAME: baseUrl + 'api/NoteBookName',
  GET_NOTEBOOK_NAMES: baseUrl + 'api/GetNoteBookName',
  /*====================== bookmark ==============*/


  /*====================== Assessments ==============*/
  SAVE_ASSESSMENT_REPORTS: baseUrl + 'api/AssessmentReport',
  CHECK_ASSESSMENT_REPORTS: baseUrl + 'api/CheckAssessmentExist',
  GET_ASSESSMENT_REPORTS: baseUrl + 'api/GetAssessmentReportList',

  /*====================== Assessments ==============*/


  /*====================== E2lAssessments ==============*/
  SAVE_E2lASSESSMENT_REPORTS: baseUrl + 'api/SaveE2lAssessmentReports',
  CHECK_E2lASSESSMENT_REPORTS: baseUrl + 'api/CheckE2lAssessmentExist',
  GET_E2lASSESSMENT_REPORTS: baseUrl + 'api/GetE2lAssessmentReportList',




  /*====================== PrimaryTestReports ==============*/
  SAVE_PRIMARY_TEST_REPORTS: baseUrl + 'api/SavePrimaryTestReports',
  GET_PRIMARY_TEST_REPORTS: baseUrl + 'api/getPrimaryTestReports',
  /*====================== PrimaryTestReports ==============*/



  /*====================== School Admin start ==============*/
  SCHOOL_ADMIN_REGISTER: baseUrl + 'api/School/AdminRegistration',
  SCHOOL_ADMIN_LOGIN: baseUrl + 'api/School/AdminLogin',
  GET_ALL_SCHOOL_RECORDS: baseUrl + 'api/School/getSchoolsList',
  GET_SCHOOL_RECORD: baseUrl + 'api/School/getSchoolrecord',
  DELETE_SCHOOL_RECORDS: baseUrl + 'api/School/DeleteSchoolRecord',

  UPDATE_ADD_SCHOOL_CLASSES: baseUrl + 'api/School/UpdateAddSchoolClasses',
  UPDATE_REMOVE_SCHOOL_CLASSES: baseUrl + 'api/School/UpdateRemoveSchoolClasses',
  UPDATE_SCHOOL_RECORDS: baseUrl + 'api/School/UpdateSchoolData',

  UPDATE_FACULTY_STATUS: baseUrl + 'api/UpdateFacultyStatus',
  UPDATE_FACULTY_PASSWORD: baseUrl + 'api/UpdateFacultyPassword',
  UPDATE_SCHOOL_PASSWORD: baseUrl + 'api/UpdateSchoolPassword',

  SCHOOL_FACULTY_REGISTER: baseUrl + 'api/School/RegisterFacultuy',
  SCHOOL_FACULTY_LOGIN: baseUrl + 'api/School/LoginFaculty',
  GET_ALL_SCHOOL_FACULTY_RECORDS: baseUrl + 'api/School/getFacultyList',
  DELETE_SCHOOL_FACULTY_RECORDS: baseUrl + 'api/School/DeleteFacultyRecord',
  BULK_OF_FACULTY_REGISTRATION: baseUrl + 'api/bulkFacultyRegistration',

  SCHOOL_STUDENT_REGISTRATION: baseUrl + 'api/School/StudentRegistration',
  GET_SCHOOL_STUDENTS_DATA: baseUrl + 'api/School/getSchoolStudentsList',
  DELETE_SCHOOL_STUDENT_RECORDS: baseUrl + 'api/School/DeleteSchoolStudentRecord',
  BULK_OF_STUDENTS_REGISTRATION: baseUrl + 'api/bulkStudentRegistration',
  UPDATE_STUDENT_CLASS: baseUrl + 'api/School/UpdateStudentClass',
  BULK_QUIZ_INSERTIONS: baseUrl + 'api/bulkQuizInsertions',
  BULK_E2LQUIZ_INSERTIONS: baseUrl + 'api/bulkE2lQuizInsertions',


  UPDATE_SCHOOL_FACULTY_STUDENTS_ACTIVE: baseUrl + 'api/UpdateSchoolFacultyStudentsActive',
  UPDATE_SCHOOL_FACULTY_LIVE_SESSIONS: baseUrl + 'api/UpdateSchoolFacultyLiveSessions',

  /*====================== School Admin end==============*/


  /* =================== Notifications start=============== =*/
  GET_NOTIFICATION_USERS_LIST: baseUrl + 'api/getNotificationUsersList',
  SEND_NOTIFICATIONS_TO_USER: baseUrl + 'api/sendMultipleNotifications',

  INSERT_NOTIFICATION_DATA: baseUrl + 'api/InsertNotificationData',

  /* =================== Notifications end =============== =*/


  /*==================== Profile start ====================*/
  UPDATE_PROFILE: baseUrl + 'api/UpdateProfile',
  /*==================== Profile end ====================*/




  /*============== Doubts Admin ================*/

  DOUBTS_ADMIN_FACULTY_REGISTER: baseUrl + 'api/DoubtsAdminFacultyRegister',
  DOUBTS_ADMIN_FACULTY_lOGIN: baseUrl + 'api/DoubtsAdminFacultyLogin',
  GET_DOUBTS_ADMIN_FACULTY_INFO: baseUrl + 'api/getDoubtsAdminFacultyInfo',
  DELETE_DOUBTS_ADMIN_FACULTY_RECORD: baseUrl + 'api/DeleteDountsAdminFacultyRecord',

  /*============== Doubts Admin ================*/



  // careers
  CAREER_REGISTRATION: baseUrl + 'api/CareerRegistertion',
  GET_CAREER_INFO: baseUrl + 'api/getCareerInfo',

  GET_NUMBER_OF_PAGE_VIEWS: baseUrl + 'api/NumberOfPageViews',



  /*============== primary section start ================*/

  INSERT_GRADE_TOPICS: baseUrl + 'api/InsertGradeTopics',
  INSERT_GRADE_SUBTOPICS: baseUrl + 'api/InsertGradeSubTopics',
  INSERT_GRADE_TESTS: baseUrl + 'api/InsertGradeTests',
  INSERT_GRADE_WORKSHEETS: baseUrl + 'api/InsertGradeWorksheets',

  GET_GRADE_TOPICS: baseUrl + 'api/getGradeTopics',
  GET_GRADE_SUBTOPICS: baseUrl + 'api/getGradeSubTopics',
  GET_GRADE_TESTS: baseUrl + 'api/getGradeTests',
  GET_GRADE_WORKSHEETS: baseUrl + 'api/getGradeWorksheets',

  UPDATE_GRADE_TOPICS: baseUrl + 'api/updateGradeTopics',
  UPDATE_GRADE_SUBTOPICS: baseUrl + 'api/updateGradeSubTopics',
  UPDATE_GRADE_TESTS: baseUrl + 'api/updateGradeTests',
  UPDATE_GRADE_WORKSHEETS: baseUrl + 'api/UpdateGradeWorksheets',

  DELETE_GRADE_TOPICS: baseUrl + 'api/deleteGradeTopics',
  DELETE_GRADE_SUBTOPICS: baseUrl + 'api/deleteGradeSubTopics',
  DELETE_GRADE_TESTS: baseUrl + 'api/deleteGradeTests',
  DELETE_GRADE_WORKSHEETS: baseUrl + 'api/DeleteGradeWorksheets',

  /*============== primary section end ================*/



  /*============== payment gateway start ================*/
  MAKE_PAYMENT: baseUrl + 'api/makePayment',
  INSERT_PAYMENT_DETAILS: baseUrl + 'api/InsertPaymentDetails',
  GET_PAID_COURSES: baseUrl + 'api/getPaidCourses',
  INSERT_AND_UPDATE_GST: baseUrl + 'api/insertGSTAndUpdate',
  GET_GST_RATE: baseUrl + 'api/getGSTRate',

  GET_PAID_USERS: baseUrl + 'api/getPaidUsers',

  GET_BEFORE_PAID_PAYMENT_DETAILS: baseUrl + 'api/getBeforePaidPaymentDetails',
  DELETE_BEFORE_PAID_PAYMENT_DETAILS: baseUrl + 'api/DeleteBeforePaidPaymentDetails',




  /*============== payment gateway end ================*/


  INSERT_CLASS: baseUrl + 'api/InsertClass',
  GET_CALSS_DATA: baseUrl + 'api/getClassData',
  UPDATE_CLASS_DATA: baseUrl + 'api/UpdateClassData',
  DELETE_CLASS: baseUrl + 'api/DeleteClass',



  /* =====================Referring Start=============== */

  SEND_REFERRAL_SMS: baseUrl + 'api/Referring/SendReferralSMS',
  SEND_REFERRAL_MAIL: baseUrl + 'api/Referring/SendReferralMail',
  GET_REFERRED_USERS: baseUrl + 'api/getReferredUsers',
  UPDATE_REFERRAL_PAYMENT: baseUrl + 'api/updateReferralPayment',
  GET_REFERAL_DETAILS: baseUrl + 'api/Referring/getReferralDetails',
  CREATE_MY_REFERRALID: baseUrl + 'api/CreateMyReferralId',
  CHECK_REFERRAL_ID_ISACTIVE: baseUrl + 'api/CheckReferralIdIsActive',
  GET_REFERRED_PAID_USERS: baseUrl + 'api/getReferredPaidUsers',

  /* =====================Referring End=============== */



  /* =====================Progress start=============== */
  GET_SUBQUESTIONSETS_DATA_LENGTH: baseUrl + 'api/GetSubQuestionSetDataLength',

  /* =====================Progress end=============== */



  /* =====================UserRegisterThroughFB start=============== */
  USER_REGISTER_THROUGH_FB: baseUrl + 'api/UserRegisterThroughFB',
  GET_USER_REGISTER_THROUGH_FB_DETAILS: baseUrl + 'api/GetUserRegisterThroughFBDetails',

  /* =====================UserRegisterThroughFB end=============== */


  //ENQUIRY
  ENQUIRY_INSERTION: baseUrl + "api/Enquiry/Insertion",
  GET_ENQUIRY_DETAILS: baseUrl + "api/Enquiry/GetAllEnquiryDetails",
  DELETE_ENQUIRY_RECORD: baseUrl + "api/Enquiry/DeleteRecord",


  // ASSIGNMENTS 
  ASSIGNMENT_INSERTION: baseUrl + "api/Assignments/Insertion",
  GET_ALL_ASSIGNMENTS: baseUrl + "api/Assignments/GetAssignments",
  UPDATE_ASSIGNMENT_DATA: baseUrl + "api/Assignments/UpdateAssignmentsData",
  DELETE_ASSIGNMENT: baseUrl + "api/Assignments/DeleteRecord",


  // SUBMITED ASSIGNMENTS
  SUBMITED_ASSIGNMENT_INSERTION: baseUrl + "api/SubmitedAssignments/Insertion",
  GET_SUBMITED_ASSIGNMENTS: baseUrl + "api/SubmitedAssignments/getSubmitedAssignments",
  UPDATE_SUBMITED_ASSIGNMENTS_MARKS: baseUrl + "api/SubmitedAssignments/UpdateSubmitedAssignmentsMarks",




  // Extra Subject Data
  ADDITIONAL_SUBJECTS_DATA_INSERTION: baseUrl + "api/ExtraSubjectsData/Insertion",
  GET_ALL_ADDITIONAL_SUBJECTS_DATA: baseUrl + "api/ExtraSubjectsData/getExtraSubjectsData",
  UPDATE_ADDITIONAL_SUBJECTS_DATA: baseUrl + "api/ExtraSubjectsData/UpdateExtraSubjectsData",
  DELETE_ADDITIONAL_SUBJECTS_DATA: baseUrl + "api/ExtraSubjectsData/DeleteExtraSubjectsData",




  // Video Sessions twilio
  CREATE_VIDEO_SESSION: baseUrl + 'api/CreateVideoSession',
  JOIN_SESSION: baseUrl + 'api/joinSession',
  END_VIDEO_SESSION: baseUrl + 'api/endVideoSession',



  //localapp
  NEW_USER_REGISTRATION: baseUrl + "api/AddLocalAppUser",
  USER_LOGIN: baseUrl + "api/LocalAppLogin",
  DELETE_LOCALAPP_USER: baseUrl + "api/DeleteLocalAppUser",
  CHECK_USER_CLASS_IS_ACTIVATED_OR_NOT: baseUrl + "api/CheckClassIsActiveOrNot",
  GET_LOCAL_APP_USERS: baseUrl + "api/getLocalAppUsers",

  ADD_NEW_CLASS_CODE: baseUrl + "api/AddLocalAppClassCodes",
  ACTIVATE_YOUR_CLASS_CODE: baseUrl + "api/ActivateyourClassCode",
  DELETE_CLASS_ACTIVATION_CODE: baseUrl + "api/DeleteLocalAppActivationCode",
  GET_LOCAL_APP_ACTIVATION_CODES: baseUrl + "api/getLocalAppActivationCodes",



} 