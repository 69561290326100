import { Component, OnInit } from '@angular/core';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute, Data } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/common.service'
import { APIURL } from 'app/url';

declare var $: any;


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

  public menuItems: any[];

  selectedClass = {
    Class: null
  };

  LoginData;
  ListOfSubjects = [];
  constructor(private router: Router,
    private route: ActivatedRoute, public translate: TranslateService
    , public commonService: CommonService, ) {
    this.LoginData = JSON.parse(localStorage.getItem('LoginUser'));
    this.selectedClass.Class = this.LoginData.Class;
  }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');

    if (this.LoginData != null) {
      if (this.LoginData.Class >= 6) {
        this.menuItems = [
          // Starting Page start
          { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          // Starting Page end

          // { path: '/learn/BrowseChapters/Maths', title: 'Maths', icon: '../assets/Icons/banking.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          // { path: '/learn/BrowseChapters/Physics', title: 'Physics', icon: '../assets/Icons/physics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          // { path: '/learn/BrowseChapters/Chemistry', title: 'Chemistry', icon: '../assets/Icons/flask.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          // { path: '/learn/BrowseChapters/Biology', title: 'Biology', icon: '../assets/Icons/dna.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

          { path: '/learn/AdditionalSubjects', title: 'Others', icon: '../assets/Icons/more.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

          {
            path: '', title: 'Tests', icon: '../assets/Icons/test.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
              { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: '/learn/assessment', title: 'School Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: '/learn/AssignmentSubjects', title: 'School Assignments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            
            ]
          },

          {
            path: '', title: 'Reports', icon: '../assets/Icons/pie-chart.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
              { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: '/learn/quizReport', title: 'Quiz Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: '/learn/AssignmentReports', title: 'Assignment Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            
            ]
          },

          { path: '/learn/LeaderBoard', title: 'Leader Board', icon: '../assets/Icons/statistics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/bookmarks', title: 'Bookmarks', icon: '../assets/Icons/bookmark2.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/doubts', title: 'Doubts', icon: '../assets/Icons/doubts.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]




        var obj = {
          Class: this.LoginData.Class,
          Group:this.LoginData.Group,
        }
        var url = APIURL.GET_DASHBOARD_DATA;
        this.commonService.postMethod(url, obj)
          .subscribe((data: Data) => {
            this.ListOfSubjects = data.response;

            this.ListOfSubjects.forEach((sub, index) => {
              this.menuItems.splice(index + 1, 0, { path: '/learn/BrowseChapters/' + sub.Subject, title: sub.Subject, icon: sub.Image, class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] });
            });

          })

        //   this.LoginData.Courses.forEach((course, index) => {
        //     this.menuItems.splice(index + 1, 0, { path: '/learn/BrowseChapters/' + course.CourseName, title: course.CourseName, icon: course.Icon, class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] });
        // });



      }

      // if (this.LoginData.Class <= 10 && this.LoginData.Class >= 6) {
      //   // this.LoginData.Class < 6
      //   this.menuItems = [
      //     // Starting Page start
      //     { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // Starting Page end

      //     { path: '/learn/BrowseChapters/Maths', title: 'Maths', icon: '../assets/Icons/banking.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Physics', title: 'Physics', icon: '../assets/Icons/physics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Chemistry', title: 'Chemistry', icon: '../assets/Icons/flask.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Biology', title: 'Biology', icon: '../assets/Icons/dna.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //     {
      //       path: '', title: 'Tests', icon: '../assets/Icons/test.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      //         { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //         { path: '/learn/assessment', title: 'College Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //       ]
      //     },

      //     {
      //       path: '', title: 'Reports', icon: '../assets/Icons/pie-chart.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      //         { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //         { path: '/learn/quizReport', title: 'Quiz Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //       ]
      //     },

      //     { path: '/learn/LeaderBoard', title: 'Leader Board', icon: '../assets/Icons/statistics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/bookmarks', title: 'Bookmarks', icon: '../assets/Icons/bookmark2.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/doubts', title: 'Doubts', icon: '../assets/Icons/doubts.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //   ]
      // }

      //  else if (this.LoginData.Class == 11 || this.LoginData.Class == 12) {
      //   this.menuItems = [
      //     // Starting Page start
      //     { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // Starting Page end
      //     { path: '/learn/BrowseChapters/Maths', title: 'Maths', icon: '../assets/Icons/banking.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Physics', title: 'Physics', icon: '../assets/Icons/physics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Chemistry', title: 'Chemistry', icon: '../assets/Icons/flask.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Biology', title: 'Biology', icon: '../assets/Icons/dna.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //     {
      //       path: '', title: 'Tests', icon: '../assets/Icons/test.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      //         { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //         { path: '/learn/assessment', title: 'College Assessments', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //       ]
      //     },

      //     {
      //       path: '', title: 'Reports', icon: '../assets/Icons/pie-chart.png', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      //         { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //         { path: '/learn/quizReport', title: 'Quiz Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //       ]
      //     },

      //     { path: '/learn/LeaderBoard', title: 'Leader Board', icon: '../assets/Icons/statistics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/bookmarks', title: 'Bookmarks', icon: '../assets/Icons/bookmark2.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/doubts', title: 'Doubts', icon: '../assets/Icons/doubts.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //   ]
      // }


      //  else if (this.LoginData.Class >= 6) {
      //   this.menuItems = [
      //     // Starting Page start
      //     { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // Starting Page end
      //     // { path: '/learn/BrowseChapters/English', title: 'English', icon: '../assets/Icons/english.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Maths', title: 'Maths', icon: '../assets/Icons/banking.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/BrowseChapters/Science', title: 'Science', icon: '../assets/Icons/science.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // { path: '/learn/BrowseChapters/History', title: 'History', icon: '../assets/Icons/history.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // { path: '/learn/BrowseChapters/Civics', title: 'Civics', icon: '../assets/Icons/civics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     // { path: '/learn/BrowseChapters/Geography', title: 'Geography', icon: '../assets/Icons/geography.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //     { path: '/learn/E2lQuizReports', title: 'E2l Quiz-Reports', icon: '../assets/Icons/e2lChart.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/quizReport', title: 'Quiz Reports', icon: '../assets/Icons/pie-chart.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/LeaderBoard', title: 'Leader Board', icon: '../assets/Icons/statistics.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/bookmarks', title: 'Bookmarks', icon: '../assets/Icons/bookmark2.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/E2lAssessment', title: 'E2l Assessments', icon: '../assets/Icons/test.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/assessment', title: 'Assessments', icon: '../assets/Icons/test1.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/doubts', title: 'Doubts', icon: '../assets/Icons/doubts.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //     { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      //   ]
      // }
      else if (this.LoginData.Class < 6) {
        this.menuItems = [
          { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/Topics/Tests', title: 'Tests', icon: '../assets/Icons/test1.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/Topics/Worksheets', title: 'Worksheets', icon: '../assets/Icons/paper.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/learn/TestReports', title: 'Reports', icon: '../assets/Icons/certificate.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

          { path: '/learn/ReferAndEarn', title: 'Refer & Earn', icon: '../assets/Icons/contact.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
      }
      else {
        this.menuItems = [
          { path: '/learn/mainPage', title: 'Dashboard', icon: '../assets/Icons/default.png', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
      }
    }


    this.visitUsersCount()
  }


  visitUsersCount() {
    var url = APIURL.INSERT_NUMBER_OF_VISITED_USERS;
    this.commonService.postMethod(url, {})
      .subscribe((data: Data) => { })
  }


}
