import { Routes, RouterModule } from '@angular/router';
import { HomePageModule } from 'app/Home/home-page.module';
import { RegisterPageModule } from 'app/registrationPages/register-pages.module';
import { AdminModule } from 'app/Admin/admin.module';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  
    // {
    //     path: '',
    //     loadChildren: () => HomePageModule
    // },
    // // {
    // //     path: 'Home',
    // //     loadChildren: './Home/home-page.module#HomePageModule'
    // // },
    // {
    //     path: 'registration',
    //     loadChildren: () => RegisterPageModule
    // },
 
    // {
    //     path: 'Admin',
    //     loadChildren: () => AdminModule
    // },
  
   

    {
        path: '',
        loadChildren: './Home/home-page.module#HomePageModule' 
    },
    // {
    //     path: 'Home',
    //     loadChildren: './Home/home-page.module#HomePageModule'
    // },
    {
        path: 'registration',
        loadChildren:'./registrationPages/register-pages.module#RegisterPageModule'
    },
 
    {
        path: 'Admin',
        loadChildren: './Admin/admin.module#AdminModule'
    },
  
    
];
