import { Component, ViewContainerRef, HostListener } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Data, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag;

// import { SwPush } from '@angular/service-worker';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './common.service';
// import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  // {"publicKey":"BL5EMLLTIWpYu6uefFpkEI-Icq5YZzlVOj_dPDFJ6FrVnG6CJSuAqaDma0UZCN9LtewVJ50zDLyWVWuEclGkLIk","privateKey":"JI1V4Jx1JcC1JzlmHeuT4Pgq1hTmbd6xF45Lgu4NBdk"}

  title = 'matangular';
  // readonly VAPID_PUBLIC_KEY = "BL5EMLLTIWpYu6uefFpkEI-Icq5YZzlVOj_dPDFJ6FrVnG6CJSuAqaDma0UZCN9LtewVJ50zDLyWVWuEclGkLIk";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    //  private modalService: NgbModal, public CommonService: CommonService,private swPush: SwPush
  ) {

    // this.swPush.requestSubscription({
    //   serverPublicKey: this.VAPID_PUBLIC_KEY
    // })
    //   .then((sub) => this.CommonService.addPushSubscriber(sub).subscribe((data)=>{
    //   }))
    //   .catch(err => console.error("Could not subscribe to notifications", err));



    // if (window.matchMedia('only screen and (max-width: 667px)').matches) {

    // } else {
    // }



    // const navEndEvents = router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // )

    // navEndEvents.subscribe((event: NavigationEnd) => {
    //   gtag('config', 'UA-134899784-1', {
    //     'page_path': event.urlAfterRedirects,
    //     // 'mobileNumber' : this.loginData.mobileNumber
    //   });
    // });

  }


}