import { Routes, RouterModule } from '@angular/router';
import { UnitModule } from 'app/learn/Units/unit.module';
import { LearnModule } from 'app/learn/learn.module';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  // {
  //   path: 'learn',
  //   loadChildren: () => LearnModule
  // },
  // {
  //   path: 'learn/Units',
  //   loadChildren: () => UnitModule
  // },
  {
    path: 'learn',
    loadChildren: './learn/learn.module#LearnModule'
  },
  {
    path: 'learn/Units',
    loadChildren: './learn/Units/unit.module#UnitModule'
  },

];