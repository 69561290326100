import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from "angularfire2/database";
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { ChatMessage } from '../models/chat-message.model';



@Injectable({
  providedIn: 'root'
})

export class ChatService {
  user: any;
  chatMessages: AngularFireList<any>;
  chatMessage: ChatMessage;
  userName: Observable<string>;

  LoginUser;

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
  ) { }




  sendMessage(data) {
    const timestamp = this.getTimeStamp();
    const email = 'easy2learn.in@gmail.com';
    this.chatMessages = this.getMessages(data);
    this.chatMessages.push({
      message: data.msg,
      timeSent: Date.now(),
      userName: data.userName,
      email: email,
      Subject: data.Subject,
      Class: data.Class,
      User: data.User,
      Image: data.selectedimg,
      NewMessage: data.NewMessage
    });
  }

  getMessages(data): AngularFireList<ChatMessage[]> {
    return this.db.list('messages/' + data.userName + '/' + data.Subject , ref => ref.orderByKey().limitToLast(100));
  }

  getTimeStamp() {
    const now = new Date();
    const date = now.getUTCFullYear() + '/' + (now.getUTCMonth() + 1) + '/' + now.getUTCDate();
    const time = now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();
    return (date + " " + time);
  }

  onlineUsers: AngularFireList<any>;
  UserUpdateToOnline(data) {
    this.db.object('userNames/' + data.MobileNumber).update({
      MobileNumber: data.MobileNumber,
      status: 'online',
      Class: data.Class
    });
  }

  UserUpdateToOffline(data) {
    this.db.object('userNames/' + data.MobileNumber).update({
      status: 'offline'
    });

  }

  getOnlineUsers(): AngularFireList<ChatMessage[]> {
    return this.db.list("userNames", ref => ref.orderByKey().limitToLast(100))
  }





  updateReadOrNot(data) {
    this.db.object('messages/' + data.userName+'/'+ data.Subject +'/'+ data.key).update({
      NewMessage: true
    });


  }










}
