import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Data } from '@angular/router';

@Directive({
  selector: '[appMainMenu]'
})
export class MainMenuDirective {
  selectedSubject;

  constructor(public el: ElementRef,
    private router: Router,
    private route: ActivatedRoute, ) {

  }


  ngAfterContentChecked() {
     var bgColor = localStorage.getItem('backgroundColor')
    //var bgColor = this.selectedSubject
    var $wrapper = $('.wrapper'),
      $sidebar = $('.app-sidebar'),
      $sidebar_content = $('.sidebar-content');

    $('.mainNavbarController').on('click', function () {
      $wrapper.addClass('nav-collapsed menu-collapsed menu-animation');
    });

    $('.mainNavbarOpen').on('click', function () {
      $wrapper.removeClass('nav-collapsed menu-collapsed menu-animation');
    });

    $('.background').on('click', function () {
      $('.wrapper').css('background-color', `${bgColor}`);
      $('.sidenavBar').css('background-color', `${bgColor}`);
      
      $('.body').css('background-color', `${bgColor}`);
    });

    $('.backgroundAfterOpenChapter').on('click', function () {
      $('.wrapper').css('background-color', `gainsboro`);
      $('.sidenavBar').css('background-color', `${bgColor}`);
      
      $('.body').css('background-color', `gainsboro`);
    });
    
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
   var width = event.target.innerWidth;
    if(width > 767){
      $('html, body').animate({scrollTop:0} , 100);
    }
  }




}
